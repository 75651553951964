import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Kantoorklachtenregeling = () => {
  return (
    <Layout>
      <Seo title="Kantoorklachtenregeling" />
      <section className="pt-60px px-30px mt-70px">
        <div className="max-w-700px mx-auto">
          <h1 className="text-3xl text-primary leading-1.2 mb-10px">
            Kantoorklachtenregeling
          </h1>
          <h2 className="text-27px text-primary leading-1.2 mb-5">
            Artikel 1 begripsbepalingen
          </h2>
          <p className="mb-30px text-primary text-lg leading-1.625">
            In deze kantoorklachtenregeling wordt verstaan onder:
          </p>
          <ul className="list-disc mb-30px pl-10">
            <li className="text-lg text-primary leading-1.625">
              klacht: iedere schriftelijke uiting van ongenoegen van of namens
              de cliënt jegens de advocaat of de onder diens
              verantwoordelijkheid werkzame personen over de totstandkoming en
              de uitvoering van een overeenkomst van opdracht, de kwaliteit van
              de dienstverlening of de hoogte van de declaratie, niet zijnde een
              klacht als bedoeld in paragraaf 4 van de Advocatenwet;
            </li>
            <li className="text-lg text-primary leading-1.625">
              klager: de cliënt of diens vertegenwoordiger die een klacht
              kenbaar maakt;
            </li>
            <li className="text-lg text-primary leading-1.625">
              klachtenfunctionaris: de advocaat die is belast met de afhandeling
              van de klacht;
            </li>
          </ul>

          <h2 className="text-primary text-27px leading-1.2 mb-5">
            Artikel 2 toepassingsbereik
          </h2>

          <ol className="mb-30px pl-10 list-decimal">
            <li className="text-primary text-lg leading-1.625">
              Deze kantoorklachtenregeling is van toepassing op iedere
              overeenkomst van opdracht tussen Mr M.A.T. Hillenaar-Cöp en de
              cliënt.
            </li>
            <li className="text-primary text-lg leading-1.625">
              De advocaat draagt zorg voor klachtafhandeling conform de
              kantoorklachtenregeling.
            </li>
          </ol>

          <h2 className="text-27px text-primary leading-1.2 mb-5">
            Artikel 3 doelstellingen
          </h2>
          <p className="text-primary text-lg leading-1.625 mb-30px">
            Deze kantoorklachtenregeling heeft tot doel:
          </p>

          <ol className="mb-30px list-lower-latin pl-10">
            <li className="text-primary text-lg leading-1.625">
              het vastleggen van een procedure om klachten van cliënten binnen
              een redelijke termijn op een constructieve wijze af te handelen;
            </li>
            <li className="text-primary text-lg leading-1.625">
              het vastleggen van een procedure om de oorzaken van klachten van
              cliënten vast te stellen;
            </li>
            <li className="text-primary text-lg leading-1.625">
              behoud en verbetering van bestaande relaties door middel van goede
              klachtenbehandeling;
            </li>
            <li className="text-primary text-lg leading-1.625">
              medewerkers te trainen in cliëntgericht reageren op klachten;
            </li>
            <li className="text-primary text-lg leading-1.625">
              verbetering van de kwaliteit van de dienstverlening met behulp van
              klachtbehandeling en klachtanalyse.
            </li>
          </ol>

          <h2 className="text-27px text-primary leading-1.625 mb-5">
            Artikel 4 informatie bij aanvang dienstverlening
          </h2>
          <ol className="mb-30px pl-10 list-decimal">
            <li className="text-primary text-lg leading-1.625">
              Deze kantoorklachtenregeling is openbaar gemaakt. De advocaat
              wijst de cliënt voor het aangaan van de overeenkomst van opdracht
              erop dat het kantoor een kantoorklachtenregeling hanteert en dat
              deze van toepassing is op de dienstverlening.
            </li>
            <li className="text-primary text-lg leading-1.625">
              Klachten als bedoeld in artikel 1 van deze kantoorklachtenregeling
              die na behandeling niet zijn opgelost worden voorgelegd aan
              rechtbank.
            </li>
          </ol>

          <h2 className="text-27px text-primary leading-1.625 mb-5">
            Artikel 5 interne klachtprocedure
          </h2>
          <ol className="mb-30px pl-10 list-decimal">
            <li className="text-primary text-lg leading-1.625">
              Indien een cliënt het kantoor benadert met een klacht, dan wordt
              de klacht doorgeleid naar mr Nancy Viellevoye-Geers, advocaat te
              Oosterbeek, die daarmee optreedt als klachtenfunctionaris.
            </li>
            <li className="text-primary text-lg leading-1.625">
              De klachtenfunctionaris stelt degene over wie is geklaagd in
              kennis van het indienen van de klacht en stelt de klager en degene
              over wie is geklaagd in de gelegenheid een toelichting te geven op
              de klacht.
            </li>
            <li className="text-primary text-lg leading-1.625">
              Degene over wie is geklaagd tracht samen met de cliënt tot een
              oplossing te komen al dan niet na tussenkomst van de
              klachtenfunctionaris.
            </li>
            <li className="text-primary text-lg leading-1.625">
              De klachtenfunctionaris handelt de klacht af binnen vier weken na
              ontvangst van de klacht of doet met opgave van redenen mededeling
              aan de klager over afwijking van deze termijn met vermelding van
              de termijn waarbinnen wel een oordeel over de klacht wordt
              gegeven.
            </li>
            <li className="text-primary text-lg leading-1.625">
              De klachtenfunctionaris stelt de klager en degene over wie is
              geklaagd schriftelijk op de hoogte van het oordeel over de
              gegrondheid van de klacht, al dan niet vergezeld van
              aanbevelingen.
            </li>
            <li className="text-primary text-lg leading-1.625">
              Indien de klacht naar tevredenheid is afgehandeld, tekenen de
              klager, de klachtenfunctionaris en degene over wie is geklaagd het
              oordeel over de gegrondheid van de klacht.
            </li>
          </ol>

          <h2 className="text-27px text-primary leading-1.625 mb-5">
            Artikel 6 geheimhouding en kosteloze klachtbehandeling
          </h2>
          <ol className="mb-30px pl-10 list-decimal">
            <li className="text-primary text-lg leading-1.625">
              De klachtenfunctionaris en degene over wie is geklaagd nemen bij
              de klachtbehandeling geheimhouding in acht.
            </li>
            <li className="text-primary text-lg leading-1.625">
              De klager is geen vergoeding verschuldigd voor de kosten van de
              behandeling van de klacht.
            </li>
          </ol>

          <h2 className="text-27px text-primary leading-1.625 mb-5">
            Artikel 7 verantwoordelijkheden
          </h2>
          <ol className="mb-30px pl-10 list-decimal">
            <li className="text-primary text-lg leading-1.625">
              De klachtenfunctionaris is verantwoordelijk voor de tijdige
              afhandeling van de klacht.
            </li>
            <li className="text-primary text-lg leading-1.625">
              Degene over wie is geklaagd houdt de klachtenfunctionaris op de
              hoogte over eventueel contact en een mogelijke oplossing.
            </li>
            <li className="text-primary text-lg leading-1.625">
              De klachtenfunctionaris houdt de klager op de hoogte over de
              afhandeling van de klacht.
            </li>
            <li className="text-primary text-lg leading-1.625">
              De klachtenfunctionaris houdt het klachtdossier bij.
            </li>
          </ol>

          <h2 className="text-27px text-primary leading-1.625 mb-5">
            Artikel 8 klachtregistratie
          </h2>
          <ol className="mb-30px pl-10 list-decimal">
            <li className="text-primary text-lg leading-1.625">
              De klachtenfunctionaris registreert de klacht met daarbij het
              klachtonderwerp.
            </li>
            <li className="text-primary text-lg leading-1.625">
              Een klacht kan in meerdere onderwerpen worden ingedeeld.
            </li>
            <li className="text-primary text-lg leading-1.625">
              De klachtenfunctionaris brengt periodiek verslag uit over de
              afhandeling van de klachten en doet aanbevelingen ter voorkoming
              van nieuwe klachten, alsmede ter verbetering van procedures.
            </li>
            <li className="text-primary text-lg leading-1.625">
              Minimaal eenmaal per jaar wordt de verslagen en de aanbevelingen
              op het kantoor besproken en ter besluitvorming voorgelegd.
            </li>
          </ol>
        </div>
      </section>
    </Layout>
  )
}

export default Kantoorklachtenregeling
